<template>
    <div>
        <div class="banner-container">
            <img class="career-img" src="../../assets/image/career/career-banner.jpg" />
            <p class="join-title">Join Our Team</p>
        </div>
        <el-breadcrumb class="breadcrumb-container" separator="/">
            <el-breadcrumb-item class="breadcrumb-item" :to="{ path: '/career' }">Career</el-breadcrumb-item>
            <el-breadcrumb-item class="breadcrumb-item">Partnership</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="job-container">
            <p class="title">Partnership</p>
            <div class="description-container">
                <p class="subtitle">Job Descriptions</p>
                <ul class="requirement-list">
                    <li class="list-details"> To develop, enable and monetize an expanding set and ecosystem of partners including ISVs, Resellers, VARs, SIs, MSPs and OEMs</li>
                    <li class="list-details"> Work with our partners and internal teams (sales, customer success, marketing, legal, product, and engineering) to optimize joint selling and marketing strategies for driving revenue through the partner ecosystem</li>
                    <li class="list-details"> Develop, monitor and adjust the strategy, ideal partner profiles and practices to continuously improve results</li>
                    <li class="list-details"> Strategic planning and reporting to the board</li>
                    <li class="list-details"> Establish clearly defined goals for each partner relationship, and update and maintain proper reporting metrics and funnel management</li>
                    <li class="list-details"> Perform quarterly partner reviews of established goals</li>
                    <li class="list-details"> Enable partners through assets and relationship management</li>
                    <li class="list-details"> Oversee co-marketing campaigns, including the production of collateral, webinars, and events</li>
                    <li class="list-details"> Be accountable for sourced and influenced revenue, partner success KPIs, and retention and integration adoption</li>
                    <li class="list-details"> Take ownership for building the entire partnership function, including hiring a team and clearly communicating the value to other departments and leadership</li>
                    <li class="list-details"> TBe willing to perform hands-on work while team is growing</li>
                </ul>
                <p class="salary">SALARY: NEGOTIABLE</p>
            </div>

            <div class="description-container">
                <p class="subtitle">How to Apply</p>
                <p class="requirement-list">Interested candidates please submit your CV/resume with a photo and accompanied by cover letter and any other supporting documents to HR Department:</p>
                <div class="hr-contact">
                    <p class="hr-contact-details">Contact Person : HR Department</p>
                    <p class="hr-contact-details">Email: hr@u-pay.com</p>
                    <p class="hr-contact-details">Contact: 069 931 888 / 077 266 123 </p>
                    <p class="hr-contact-details">Website: www.u-pay.com</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }
  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}
.job-container {
    display: table;
    margin-right: auto;
    margin-left: auto;
    padding: 3% 25% 3% 25%;
    .title {
        color: #0277E3;
        text-align: center;
        font-size: 1.55rem;
        font-weight: bold;
    }
}

.description-container{
    padding-top: 8%;
    .subtitle {
        color: black;
        text-align: center;
        font-size: 1.35rem;
        font-weight: bold;
    }
    .requirement-list{
        margin: 3% 0% 5% 0%;

        .list-details {
            margin-bottom: 0.5%;
            display: list-item;
            list-style-type: disc;
        }
    }
    .salary{
        font-weight: bolder;
    }

    .hr-contact .hr-contact-details {
        margin-bottom: 3%;
        font-weight: bold;
    }
}

.breadcrumb-container {
    margin : 3% 3%;
}

.breadcrumb-item {
    color: darkgray;
    font-weight: 600;
    font-size: 1.0rem;
}

@media only screen and (max-width: 600px) {
    .job-container {
        display: table;
        margin-right: auto;
        margin-left: auto;
        padding: 3% 5% 3% 5%;
        .title {
            color: #0277E3;
            text-align: center;
            font-size: 1.10rem;
            font-weight: bold;
        }
    }
}
</style>
